import React, { createContext, useEffect, useState } from 'react'
import { tablesRealTime, saveTable, deleteTable, tableHistoryOpenRealTime, closeTableHistory, updateTable, getTableHistory, updateTableHistory } from '../firebase/obj/tables'
import useMainContext from '../hooks/useMainContext'
import { getAllPaymentMethods } from '../firebase/obj/paymentMethods'
import { getOrderById, updateOrder } from '../firebase/obj/order'

export const TableContext = createContext()

const Provider = ({ children }) => {
    const [tables, setTables] = useState([])
    const [tableHistories, setTableHistories] = useState({})
    const [paymentMethods, setPaymentMethods] = useState([])
    const { isAdmin } = useMainContext()

    useEffect(() => {
        if (isAdmin) {
            const subscriber = tablesRealTime((tables) => {
                //ordenar las mesas por id, pero convertirlas a numeros antes
                const tablesOrdered = tables.sort((a, b) => Number(a.id) - Number(b.id))
                setTables(tablesOrdered)
            })
            getPaymentMethods()
            return () => subscriber()
        }
    }, [isAdmin])

    useEffect(() => {
        tables?.forEach(table => {
            if (table.busy && table.tableHistoryId) {
                const subscriber = tableHistoryOpenRealTime(table.id, (history) => {
                    const tableHistory = history.at(0)
                    setTableHistories(prevState => ({ ...prevState, [table.id]: tableHistory }))
                })
                return () => subscriber()
            }
        })
    }, [tables])

    const getPaymentMethods = async () => {
        try {
            const paymentMethods = await getAllPaymentMethods()
            setPaymentMethods(paymentMethods.filter(t => t.tableOption))
        } catch (error) {
            console.error('Error al obtener los metodos de pago', error)
        }
    }


    const handleSaveTable = async (table) => {
        try {
            await saveTable(table)
            return { success: true }
        } catch (error) {
            console.error('handleSaveTable', error)
            return { success: false, error }
        }
    }

    const handleDeleteTable = async (table) => {
        try {
            await deleteTable(table.id)
            return { success: true }
        } catch (error) {
            console.error('handleDeleteTable', error)
            return { success: false, error }
        }
    }

    const handleCloseTable = async (table) => {
        try {
            console.log('handleCloseTable table', table)
            let tableHistory = tableHistories[table.id]
            if (!tableHistory) {
                tableHistory = await getTableHistory(table.id, table.tableHistoryId)
            }
            console.log('handleCloseTable tableHistory', tableHistory)
            if (tableHistory) {
                tableHistory.state = 'closed'
                tableHistory.closedAt = new Date()
                tableHistory.totalAmount = tableHistory.orders?.reduce((acc, order) => acc + Number(order.amount || 0), 0)
                await closeTableHistory(tableHistory)

                //verificar que los pedidos tengan el mismo metodo de pago
                await updateOrdersPaymentMethod(tableHistory)
            }
            await updateTable({ ...table, busy: false, tableHistoryId: '', clientName: '' })

        } catch (error) {
            console.error('Error al cerrar la mesa', error)
        }
    }

    const updateOrdersPaymentMethod = async (tableHistory) => {
        try {
            const paymentMethod = tableHistory.paymentMethod
            if (paymentMethod) {
                const orders = tableHistory.orders || []
                const ordersWithOtherPaymentMethod = orders.filter(order => order.paymentMethod !== paymentMethod)
                ordersWithOtherPaymentMethod?.forEach(async order => {
                    const orderDB = await getOrderById(order.id)
                    orderDB.paymentMethod = paymentMethod
                    await updateOrder(orderDB)
                })
            }
        } catch (error) {
            console.error('updateOrdersPaymentMethod', error)
        }
    }

    const updateCurrentTableHistory = (tableId, history) =>
        updateTableHistory(tableId, history.id, history)


    const value = {
        tables,
        tableHistories,
        paymentMethods,
        saveTable: handleSaveTable,
        updateTable: updateTable,
        deleteTable: handleDeleteTable,
        closeTable: handleCloseTable,
        updateCurrentTableHistory,
    }

    return (
        <TableContext.Provider value={value}>
            {children}
        </TableContext.Provider>
    )
}

const context = {
    Provider,
    Consumer: TableContext.Consumer
}

export default context