import React, { useState, useEffect, Fragment } from 'react'
import {
    Grid,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getOrdersByClientIdRealTime, lastOrderByClient } from '../firebase/obj/order'
import OrderViewCard from '../components/order/orderView/card'
import Divider from '../components/divider'
import useMainContext from '../hooks/useMainContext'

export const useStyles = makeStyles((theme) => ({
    horizontalScroll: {
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 0',
        '&::-webkit-scrollbar': {
            height: 10,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#E24620',
            borderRadius: 10,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#F7F9F9',
            borderRadius: 10,
        },
    },

}))

export const MyOrdersWithData = ({ type = 'section' }) => {
    const classes = useStyles()
    const { user, config } = useMainContext()
    const [orders, setOrders] = useState([])
    const [lastOrders, setLastOrders] = useState([])
    const [showDividers, setShowDividers] = useState(false)

    useEffect(() => {
        if (user) {
            const {
                firstClientState = 'Pendiente',
                firstState = 'En cocina',
                delayState = 'Demorado',
                cookedState = 'Finalizado',
                assignedState = 'En reparto',
                lastState = 'Entregado',
                canceledState = 'Cancelado'
            } = config || {}
            const states = [firstClientState, firstState, delayState, cookedState, assignedState]

            if (type === 'page') {
                lastOrderByClient(user.uid, [lastState, canceledState], 10).then(data => setLastOrders(data))
            }

            const unsubscribe = getOrdersByClientIdRealTime({ clientId: user.uid, orderStates: states, setState: setOrders })

            return () => unsubscribe()
        }

    }, [user, config, type])

    useEffect(() => {
        if (orders?.length > 0) {
            setShowDividers(true)
        }
    }, [orders])


    if (orders.length === 0 && lastOrders.length === 0)
        return <Fragment />

    return (
        <>
            {
                showDividers && <Divider />
            }
            {
                orders.length > 0 &&
                <Grid container spacing={1} direction="row" justify="center" alignItems="center" >
                    <Grid item xs={12} style={{ padding: '0 20px' }}>
                        <Typography variant="h6" style={{ color: '#333', fontSize: 50, fontStyle: 'italic' }} align='center' fullWidth >
                            {`Pedidos en proceso (${orders.length})`}
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={2} justify="center" alignItems="center" style={{ padding: 10 }} >
                        {
                            orders.map(order => <OrderViewCard key={order.id} order={order} config={config} />)
                        }
                    </Grid>

                </Grid>
            }
            {
                lastOrders.length > 0 && orders.length > 0 && < Divider />
            }
            {
                type === 'page' && lastOrders.length > 0 &&
                <Grid container spacing={0} direction="row" justify="center" alignItems="center" style={{ padding: '0 12vw  20px 12vw' }}>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ color: '#333', fontSize: 50, fontStyle: 'italic' }} align='center' fullWidth >
                            {`Últimos pedidos`}
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={2} justify="center" alignItems="center" style={{ padding: 10, width: '90vw' }} >
                        <div className={classes.horizontalScroll}>
                            {
                                lastOrders.map(order =>
                                    <OrderViewCard key={order.id} order={order} config={config} mode='history' />
                                )}
                        </div>
                    </Grid>
                </Grid>

            }
            {
                showDividers && <Divider />
            }
        </>
    )
}

export default MyOrdersWithData