import React, { Fragment } from 'react'
import MyOrdersWithData from '../containers/myOrdersWithData'
import Title from '../components/title'


export const MyOrdersPage = () => {

    return (
        <Fragment>
            <Title>Mis pedidos</Title>
            <MyOrdersWithData type={'page'} />
        </Fragment>
    )
}

export default MyOrdersPage