//import * as firebase from 'firebase'
import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import { snapshotToArray } from '../../utils'
import { saveError } from './error'
import { getCurrentUser } from './user'
import moment from 'moment'

//<, <=, ==, >=, >, array-contains, in, array-contains-any}
// .where('orderState', 'in', ['En proceso', 'Entregado'])

export let getOrdersRealTimeUnsubscribe = () => { }
export let getOrdersRealTimeWithDateUnsubscribe = () => { }

export const getOrdersRealTime = (setState = () => { }, orderStates = ['En cocina', 'Demorado']) =>
  firebase.app().firestore().collection('orders')
    .where('orderState', 'in', orderStates)
    .orderBy('date', 'asc')
    .onSnapshot(querySnapshot => setState(snapshotToArray(querySnapshot.docs)))


export const getOrdersDeliveryRealTime = (setState = () => { }, orderStates = ['En reparto'], delivery = '') => {
  firebase.app().firestore().collection('orders')
    .where('orderState', 'in', orderStates)
    .where('delivery', '==', delivery)
    .onSnapshot(querySnapshot => setState(snapshotToArray(querySnapshot.docs)))
}

export const getOrdersBetweenDatesRealTime = (setState = () => { }, orderStates = ['En cocina', 'Demorado'], startDate = new Date(), endDate = new Date()) => {

  //console.log('startDate', startDate)
  //console.log('endDate', endDate)
  return firebase.app().firestore().collection('orders')
    .where('orderState', 'in', orderStates)
    .where("date", ">=", startDate)
    .where("date", "<=", endDate)
    .orderBy('date', 'asc')
    .onSnapshot(querySnapshot => {
      //console.log('querySnapshot.docs: ', querySnapshot.docs)
      setState({ orders: snapshotToArray(querySnapshot.docs) })
    })
}

export const getOrderByIdRealTime = (id, setState = () => { }) =>
  firebase.app().firestore().collection('orders').doc(id)
    .onSnapshot(doc => setState(doc.data()))

export const getOrdersByClientIdRealTime = ({ clientId, orderStates = [], limit = 50, setState = () => { } }) =>
  firebase.app().firestore().collection('orders')
    .where("clientId", "==", clientId)
    .where('orderState', 'in', orderStates)
    .orderBy('date', 'desc')
    .limit(limit)
    .onSnapshot(querySnapshot => {
      //console.log('getOrdersByClientIdRealTime querySnapshot.docs: ', querySnapshot.docs)
      setState(snapshotToArray(querySnapshot.docs))
    })

export const lastOrderByClient = (clientId, orderStates = [], limit = 10) => new Promise((resolve, reject) => {
  firebase.app().firestore().collection('orders')
    .where("clientId", "==", clientId)
    .where('orderState', 'in', orderStates)
    .orderBy('date', 'desc')
    .limit(limit)
    .get()
    .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
    .catch(reject)
})



export const getOrdersWithDate = (startDate = new Date(), endDate = new Date()) =>
  new Promise((resolve, reject) => {
    firebase.app().firestore().collection('orders')
      .where("date", ">=", startDate)
      .where("date", "<=", endDate)
      .orderBy('date', 'asc')
      .get()
      .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
  })


export const getOrders = () => new Promise((resolve, reject) => {
  firebase.app().firestore().collection('orders')
    .get()
    .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
    .catch(error => reject(saveError(error, 'getOrders')))
})

export const saveOrder = order => {
  // this.db.collection('post').doc('key').set({})
  //if(producto.id) return this.editar(producto)
  return new Promise((resolve, reject) => {
    const user = getCurrentUser()
    //console.log('saveOrder')

    if (!order.date || moment(order.date).isBefore(moment())) {
      order.date = new Date()
    }

    firebase.app().firestore().collection('orders').add({
      ...order,
      userEmail: user.email || ''
    })
      .then(ref => resolve(ref.id))
      .catch(error => reject(saveError(error, 'saveOrder')))
  }
  )
}

export const updateOrder = order => new Promise((resolve, reject) => {
  const user = getCurrentUser()
  //console.log('updateOrder')
  firebase.app().firestore().collection('orders').doc(order.id).update({
    ...order,
    userEmail: user.email || '',
    createdAt: new Date()
  })
    .then(resolve('Pedido modificado'))
    .catch(error => reject(saveError(error, 'updateOrder')))
})

export const getOrderById = id => new Promise((resolve, reject) => {
  firebase.app().firestore().collection('orders').doc(id).get()
    .then(doc => {
      !doc.exists && resolve({})
      resolve({ ...doc.data(), id })
    })
    .catch(error => reject(saveError(error, 'getOrderById')))
})

export const deleteOrder = (orderId) => new Promise((resolve, reject) => {
  firebase.app().firestore().collection('orders').doc(orderId).delete()
    .then(resolve('Pedido borrado'))
    .catch(error => reject(saveError(error, 'deleteOrder')))
})


export const saveOrderHistory = history => new Promise((resolve, reject) => {
  firebase.app().firestore().collection('orders').doc(history.orderId).collection('history').add({
    ...history,
    date: new Date(),
  })
    .then(ref => resolve(ref.id))
    .catch(error => reject(saveError(error, 'saveOrderHistory')))
}
)

export const getOrderHistory = orderId => new Promise((resolve, reject) =>
  firebase.app().firestore().collection('orders').doc(orderId).collection('history')
    .orderBy('date', 'asc')
    .get()
    .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
    .catch(error => reject(saveError(error, 'getOrderHistory')))
)

















