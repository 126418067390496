import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import {
    getCollectionFiltered,
    getDocument,
    saveDocument,
    updateDocument,
    deleteDocument
} from './abm'
import { snapshotToArray } from '../../utils'

const collection = 'zones'

export const getZonesRealTime = (setState = () => { }) =>
    firebase.app().firestore().collection(collection)
        .orderBy('name', 'asc')
        .onSnapshot(
            querySnapshot => {
                setState(snapshotToArray(querySnapshot.docs))
            },
            error => {
                console.error("Error al leer zonas en tiempo real", error)
            }
        )


export const getZoneData = id => getDocument(id, collection)

export const getAllZones = () => getCollectionFiltered({ collection })

export const addZone = zone => saveDocument(zone, collection, zone.id)

export const updateZone = zone => updateDocument(zone, collection)

export const deleteZone = id => deleteDocument(id, collection)
