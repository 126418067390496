//import * as firebase from 'firebase'
import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import { snapshotToArray } from '../../utils'
import { saveError } from './error'

export const getRoles = () => {
    return new Promise((resolve, reject) => {
        firebase.app().firestore().collection('roles')
            .get()
            .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
            .catch(error => reject(saveError(error, 'getRoles')))
    })
}

export const getRolesFiltered = async (rolesIds = []) => {
    try {
        if (!rolesIds?.length) return []
        const rolesFiltered = await firebase.app().firestore().collection('roles').where(firebase.firestore.FieldPath.documentId(), 'in', rolesIds).get()
        return snapshotToArray(rolesFiltered.docs)
    } catch (error) {
        console.error('getRolesFiltered', error)
        // return saveError(error, 'getRolesFiltered')
    }
}