/* import React from 'react'
import { FirestoreCollection } from 'react-firestore' */
//import * as firebase from 'firebase'
import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import { snapshotToArray } from '../../utils'
import { saveError } from './error'

export default class Client {
  constructor() {
    this.id = ''
    this.name = ''
    this.address = ''
    this.phone = ''
    this.region = ''
    this.active = true
  }
}

/* var citiesRef = db.collection("cities")
    citiesRef.whereArrayContains("regions", "west_coast") */

export let getClientsRealTimeUnsubscribe = () => { }

export const getClientsRealTime = (setState = () => { }) => {
  firebase.app().firestore().collection('clients')
    .orderBy('name', 'asc')
    .onSnapshot(querySnapshot => {
      setState(snapshotToArray(querySnapshot.docs))
    })
}

export const getClientsFiltered = (field = null, value = null, startAt = null, limit = 0, orderBy = null, getSize = () => { }) => new Promise((resolve, reject) => {
  let clients = firebase.app().firestore().collection('clients').limit(1)
  if (field && value) {
    clients = clients.where(field, '>=', value)
  }
  if (orderBy) {
    clients = clients.orderBy(orderBy)
  }
  if (startAt) {
    clients = clients.startAt(startAt)
  }
  if (limit > 0) {
    clients = clients.limit(limit)
  }
  clients
    .get()
    .then(querySnapshot => {
      getSize(querySnapshot.size)
      resolve(snapshotToArray(querySnapshot.docs))
    })
    .catch(error => reject(saveError(error, 'getClientsFiltered')))
})

export const getClients = () =>
  new Promise((resolve, reject) =>
    firebase.app().firestore().collection('clients')
      .where('active', '==', true)
      .orderBy('name', 'asc')
      .get()
      .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
      .catch(error => reject(saveError(error, 'getClients')))
  )


export const getClientData = async (id) => {
  try {
    const doc = await firebase.app().firestore().collection('clients').doc(id).get()
    if (!doc.exists) return { error: 'No existe el cliente' }
    return doc.data()
  } catch (error) {
    console.error('getClientData', error)
    saveError(error, 'getClientData')
  }
  return {}
}

export const getAllClients = () => {
  return new Promise((resolve, reject) => {
    firebase.app().firestore().collection('clients')
      .orderBy('name', 'asc')
      .get()
      .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
      .catch(error => reject(saveError(error, 'getAllClients')))
  })
}

export const addClient = client => new Promise((resolve, reject) => {
  firebase.app().firestore().collection('clients').add({
    ...client,
    nameLowerCase: client.name.toLowerCase(),
    addressLowerCase: client.address.toLowerCase(),
    created: new Date()
  })
    .then(docRef => resolve(docRef.id))
    .catch(error => reject(saveError(error, 'addClient')))
})

export const saveClient = (client) => new Promise((resolve, reject) => {
  client.id ?
    firebase.app().firestore().collection('clients').doc(client.id).update({
      ...client,
      nameLowerCase: client.name?.toLowerCase() || '',
      addressLowerCase: client.address?.toLowerCase() || '',
      created: client.created ? client.created : new Date()
    })
      .then(resolve({ message: 'Cliente modificado', id: client.id }))
      .catch(error => reject(saveError(error, 'saveClient > update')))
    :
    firebase.app().firestore().collection('clients').add({
      ...client,
      nameLowerCase: client.name?.toLowerCase() || '',
      addressLowerCase: client.address?.toLowerCase() || '',
      created: new Date()
    })
      .then(docRef => {
        firebase.app().firestore().collection('clients').doc(docRef.id).update({
          ...client,
          id: docRef.id
        })
          .then(resolve({ message: 'Cliente creado', id: docRef.id }))
          .catch(error => reject(saveError(error, 'saveClient > updateId')))
      })
      .catch(error => reject(saveError(error, 'saveClient > add')))
})

export const updateClient = client => new Promise((resolve, reject) =>
  firebase.app().firestore().collection('clients').doc(client.id).update({
    ...client
  })
    .then(resolve({ message: 'Cliente modificado' }))
    .catch(error => reject(saveError(error, 'updateClient')))
)


export const deleteClient = (clientId) => new Promise((resolve, reject) => {
  firebase.app().firestore().collection('clients').doc(clientId).delete()
    .then(resolve({ message: 'Cliente borrado' }))
    .catch(reject({ message: 'Error al borrar cliente' }))
})

export const getClientAddresses = (clientId) => new Promise((resolve, reject) =>
  firebase.app().firestore().collection('clients').doc(clientId).collection('addresses')
    .get()
    .then(querySnapshot => resolve(snapshotToArray(querySnapshot.docs)))
    .catch(error => reject(saveError(error, 'getClientAddresses')))
)

export const saveClientAddress = (address) => new Promise((resolve, reject) =>
  firebase.app().firestore().collection('clients').doc(address.clientId).collection('addresses').add({
    ...address,
    date: new Date(),
  })
    .then(ref => resolve({ id: ref.id }))
    .catch(error => reject(saveError(error, 'saveAddress')))
)

export const updateClientAddress = address => new Promise((resolve, reject) =>
  firebase.app().firestore().collection('clients').doc(address.clientId).collection('addresses').doc(address.id).update({
    ...address,
  })
    .then(ref => resolve('success'))
    .catch(error => reject(saveError(error, 'updateClientAddress')))
)

export const getClientPoints = clientId => new Promise((resolve, reject) =>
  firebase.app().firestore().collection('clientPoints').doc(clientId)
    .get()
    .then(doc => {
      if (doc.exists)
        resolve(doc.data())
      else
        resolve({ points: 0, exists: false, message: 'No se encontró cliente' })
    })
    .catch(error => reject(saveError(error, 'getClientPoints'))))

export const getClientPointsRealTime = (clientId, setState = () => { }) =>
  firebase.app().firestore().collection('clientPoints').doc(clientId)
    .onSnapshot(doc => {
      if (!doc.exists)
        return setState(0)
      setState(doc.data().points)
    })
